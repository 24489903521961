<template>
<svg width="1600px" height="900px" viewBox="0 0 1600 900" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)" id="radialGradient-3">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%"></stop>
        </radialGradient>
        <path d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0" id="path-4"></path>
        <filter x="-101.6%" y="-70.7%" width="303.1%" height="248.3%" filterUnits="objectBoundingBox" id="filter-5">
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Casinos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Venetian">
            <g id="path-venetian">
                <g id="Route" stroke-linecap="round" stroke-linejoin="round">
                    <filter id="glow-1" x="-2" y="-2" width="200" height="200">
                        <feGaussianBlur stdDeviation="5"/>
                    </filter>
                    <path filter="url(#glow-1)" stroke="#FFFFFF" stroke-width="6.00000024" d="M839.600005,171.200001 C843.200005,174.666669 845.000005,178.000002 845.000005,181.200001 C845.000005,186 842.81543,191.984375 847.699219,191.984375 C850.955078,191.984375 852.225586,189.989583 851.510742,186 L851.510742,178.878906 C846.127155,168.426301 839.156909,163.799999 830.600005,165 L816.466797,206.737305 L810.853516,242.328125 L810.853516,745.488281 C811.067708,751.507161 805.663737,754.516602 794.641602,754.516602 C783.619466,754.516602 760.846029,753.951497 726.321289,752.821289 L698.871094,757.976563 L638.483267,757.976563 C636.056922,757.771484 634.84375,759.782227 634.84375,764.008789 C634.84375,768.235352 634.84375,771.899089 634.84375,775 L638.200005,775" class="path-glow"></path>
                    <path stroke="#FFFFFF" stroke-width="6.00000024" d="M839.600005,171.200001 C843.200005,174.666669 845.000005,178.000002 845.000005,181.200001 C845.000005,186 842.81543,191.984375 847.699219,191.984375 C850.955078,191.984375 852.225586,189.989583 851.510742,186 L851.510742,178.878906 C846.127155,168.426301 839.156909,163.799999 830.600005,165 L816.466797,206.737305 L810.853516,242.328125 L810.853516,745.488281 C811.067708,751.507161 805.663737,754.516602 794.641602,754.516602 C783.619466,754.516602 760.846029,753.951497 726.321289,752.821289 L698.871094,757.976563 L638.483267,757.976563 C636.056922,757.771484 634.84375,759.782227 634.84375,764.008789 C634.84375,768.235352 634.84375,771.899089 634.84375,775 L638.200005,775" class="path-line"></path>
                </g>
                <g id="Ending-Point" transform="translate(623.800004, 760.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
                </g>
                <image id="Bitmap" x="498" y="710" width="157" height="141" href="@/assets/images/icons/end.png"></image>
                <g id="Starting-Point" transform="translate(825.800004, 144.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="27.6000011" r="4.80000019"></circle>
                    <g id="Fill-1">
                        <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
                    </g>
                </g>
                <text id="The-Venetian" font-family="sans-serif" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
                    <tspan x="868" y="176">The Venetian & The Palazzo</tspan>
                </text>
                <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1"></rect>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    name: "VenetianPath",
    mounted() {
        var path = document.querySelector('.path-line')
        var length = path.getTotalLength()
        path.style.transition = path.style.WebkitTransition = 'none'
        path.style.strokeDasharray = length + ' ' + length
        path.style.strokeDashoffset = length
        path.getBoundingClientRect()
        path.style.transition = path.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        path.style.strokeDashoffset = '0'

        var pathGlow = document.querySelector('.path-glow')
        var lengthGlow = pathGlow.getTotalLength()
        pathGlow.style.transition = pathGlow.style.WebkitTransition = 'none'
        pathGlow.style.strokeDasharray = lengthGlow + ' ' + lengthGlow
        pathGlow.style.strokeDashoffset = lengthGlow
        pathGlow.getBoundingClientRect()
        pathGlow.style.transition = pathGlow.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        pathGlow.style.strokeDashoffset = '0'
    }
}
</script>

<style lang="scss" scoped>

</style>